<template>
    <div class="show-my-commend p20 bsbb">
    <van-nav-bar title="我的评价" left-arrow @click-left="onClickLeft" />
        <div style="color:rgba(89, 194, 103, 1)" class="fz28 fw4 mt20">我的评价({{ lengths }})</div>
        <div class="content mt40">
            <div class="item df mb20" v-for="(v, i) in allMyCommend" :key="i">
                <img src="../../assets/images/xg/home.png" style="width: 80px;height: 80px;border-radius: 50%;" class="fn">
                <div class="df fdc text f1 mt40">
                    <div class="df fdc">
                        <p class="fz22">{{ v.user_name }}</p>
                        <div class="df aic fz16 mt10">
                            <span>打分</span>
                            <van-rate v-model="v.user_grade" />
                        </div>
                    </div>
                    <p class="fz26 fw7 mt20">文案文案文案文案</p>
                    <div class="imgs df aic jcsb fww">
                        <img :src="v.user_img
                        " style="width:32%">
                    </div>
                    <div class="df aic jcsb" style="margin-top: 10px;">
                        <p class="fz16">浏览55</p>
                        <div class="df aic jcsb" style="width:100px;height:20px">
                            <van-button style="width:50px;height:20px;border-radius:20px;margin-right:10px"
                                type="success">点赞</van-button>
                            <span class="fz16" @click="deleteItem(v.id)">删除</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="item df mb20">
                <img src="../../assets/images/xg/home.png" style="width: 80px;height: 80px;border-radius: 50%;" class="fn">
                <div class="df fdc text f1 mt40">
                    <div class="df fdc">
                        <p class="fz22">ANNA</p>
                        <div class="df aic fz16 mt10">
                            <span>打分</span>
                            <van-rate v-model="value" />
                        </div>
                    </div>
                    <p class="fz26 fw7 mt20">文案文案文案文案</p>
                    <div class="imgs df aic jcsb fww">
                        <img src="../../assets/images/xg/home.png" style="width:32%">
                        <img src="../../assets/images/xg/home.png" style="width:32%">
                        <img src="../../assets/images/xg/home.png" style="width:32%">
                        <img src="../../assets/images/xg/home.png" style="width:32%">
                        <img src="../../assets/images/xg/home.png" style="width:32%">
                        <img src="../../assets/images/xg/home.png" style="width:32%">
                        <img src="../../assets/images/xg/home.png" style="width:32%">
                        <img src="../../assets/images/xg/home.png" style="width:32%">
                        <img src="../../assets/images/xg/home.png" style="width:32%">
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { deleteComment, getAllMyEvaluate } from "@/utils/api.js"
export default {
    data() {
        return {
            value: 5,
            allMyCommend: [],
            lengths: '',
        }
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1);
        },
        deleteItem(n) {
            deleteComment({ id: n }).then(res => {
                console.log(res)
            })
        }
    },
    mounted() {
        getAllMyEvaluate({ page: 1, page_size: 30 }).then(res => {
            this.allMyCommend = res.data.list
            this.lengths = res.data.count
        });
    },
}
</script>

<style lang="scss" scoped>
.show-my-commend {
    background-color: #fff;
    font-family: 'siyuan';

    .content {
        width: 100%;
        background-color: #fff;

        .item {
            width: 690px;

            .text {
                .imgs {
                    width: 500px;
                }
            }
        }
    }
}
</style>
<style lang="scss">
.van-nav-bar__title{
    font-size: 32px;
    line-height: normal;
}
.van-nav-bar{
    height: 60px;
}
.van-nav-bar__left{
    font-size: 32px;
}
</style>